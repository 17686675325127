// Locator

$(function() {
  'use strict';

  var pins = {
    agence: {
      primary: '/app/themes/default/assets/images/locator/pin-agence-primary.svg',
      secondary: '/app/themes/default/assets/images/locator/pin-agence-secondary.svg',
      size: {
        width: 29,
        height: 35
      }
    },
    direction: {
      primary: '/app/themes/default/assets/images/locator/pin-direction-primary.svg',
      secondary: '/app/themes/default/assets/images/locator/pin-direction-secondary.svg',
      size: {
        width: 48,
        height: 48
      }
    },
    direction_g: {
      primary: '/app/themes/default/assets/images/locator/pin-direction_g-primary.svg',
      size: {
        width: 48,
        height: 48
      }
    },
    siege: {
      primary: '/app/themes/default/assets/images/locator/pin-siege-primary.svg',
      size: {
        width: 30,
        height: 31
      }
    }
  };

  var Locator = {
    config: {},

    elements: {
      $root: false,
      $map: false,
      $results: false,
    },

    map: false,
    markers: [],
    displayedMarkers: [],
    io: false,
    cluster: false,
    mapStyles: [],


    // Populate variables
    __construct: function($root){
      var _ = this;

      _.elements.$root = $root;
      _.elements.$map = $root.find('.js-locator-map');
      _.elements.$results = $root.find('.js-locator-result');

      return _;
    },

    // Initialize locator
    init: function(){
      var _ = this;

      // Create map
      _.map = new google.maps.Map(_.elements.$map.get(0), {
        center: {lat: 0, lng: 0},
        zoom: 2,
        disableDefaultUI: true,
        zoomControl: true,
      });

      // Create markers
      _.elements.$results.each(function(){
        var $result = $(this);

        var marker = new google.maps.Marker({
          position: new google.maps.LatLng($result.data('lat'), $result.data('lng')),
          map: _.map,
          icon: {
            url: pins[$result.data('type')][$result.data('theme')],
            scaledSize: new google.maps.Size(pins[$result.data('type')]['size'].width, pins[$result.data('type')]['size'].height),
          },
          $ioContent: $result.children(),
          group: $result.data('type'),
        });

        marker.defaultIcon = marker.icon;

        _.markers.push(marker);

        // Click
        marker.addListener('click', function(){
          $('.js-locator-result').not($result).removeClass('is-active');
          if($result.hasClass('is-active')) {
            $result.removeClass('is-active');
          }
          else {
            $result.addClass('is-active');
          }
        });
      });

      // Initialize clusterer
      _.cluster = new MarkerClusterer(_.map, _.markers, {
        styles: [{
          url: '/app/themes/default/assets/images/locator/cluster.svg',
          width: 50,
          height: 50,
          textColor: '#fff',
          textSize: 14,
          fontFamily: 'inherit',
        }]
      });


      // Zoom in/out map according to the density of markers
      _.fitBounds();
    },

    // Zoom in/out map according to the density of markers
    fitBounds: function(){
      var _ = this;

      if(_.markers.length){
        var bounds = new google.maps.LatLngBounds();

        for(var i=0; i<_.markers.length; i++){
          bounds.extend(_.markers[i].getPosition());
        }

        _.map.fitBounds(bounds);
      }
    },
  };

  // Init multiple locators once GMAP API is loaded
  window.initLocators = function(){
    $('.js-locator').each(function(){
      var locator = Locator.__construct($(this));
      locator.init();
    });
  };

  $(document).ready(initLocators);
});
