// Menu

$(function() {
  'use strict';

  var $toggle = $('.js-toggle-menu'),
      $menu   = $('.js-menu');

  $toggle.on('click', function(event) {
    event.preventDefault();

    $body.toggleClass('menu-active');
  });

});
