$(function() {
  'use strict';

  $('.js-slider').slick({
    slidesToShow: 4,
    autoplay: false,
    arrows: false,
    dots: false,
    infinite: false,
    variableWidth: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          speed: 800,
          infinite: true,
          centerMode: true,
          variableWidth: true
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          speed: 800,
          infinite: true,
          variableWidth: false
        }
      }
    ]
  });

});
