// Panel

$(function() {
  'use strict';

  $('.js-flip-panel').on('click', function(event) {
    event.preventDefault();

    var $this  = $(this),
        $panel = $this.parents('.js-panel');

    $panel.toggleClass('is-flipped');
  });

  $('.js-close-panel').on('click', function(event) {
    event.preventDefault();

    var $this  = $(this),
        $panel = $this.parents('.js-panel'),
        $item  = $panel.parents('.js-locator-result');

    $panel.removeClass('is-flipped');
    $item.removeClass('is-active');
  });

});
