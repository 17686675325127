// Welcome

$(function() {
  'use strict';

  $(document).ready(function() {

    if($('.js-welcome').length > 0) {
      $('.js-welcome').addClass('is-active');
    }
  });

});
